<template>
	<div>
		<div class="banner">
			<div class="banner-content">
				<h2>客宝宝</h2>
				<p> 专注于企业售后服务的业务模式创新 <br>
					和精益化管理</p>
			</div>
		</div>
		<div class="brief">
			<div class="brief-title">
				客宝宝隐私政策
			</div>
			<div class="brief-content">
				<!--        <div class="brief-img">
          <img src="../../../public/kbb.png" alt="">
        </div> -->
				<div class="brief-content">
					<!-- 					<p>在此特别提醒您(用户)在注册成为用户之前，请认真阅读本《用户协议》(以下简称“协议”)，确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。本协议约定深圳赛通信息科技有限公司(以下简称“客宝宝APP")与用户之间关于“客宝宝APP"软件服务(以下简称“服务")的权利义务。“用户“是指注册、登录、使用本服务的个人。
					</p>
					<p>本协议可由客宝宝APP随时更新,更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知,用户可在本APP中查阅最新版协议条款。在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用客宝宝APP提供的服务,用户继续使用服务将被视为接受修改后的协议。
					</p>
					<p>我们的产品基于DCloud uni-app(5+ App/Wap2App)开发，应用运行期间需要收集您的设备唯一识别码（IMEI/android ID/DEVICE_ID/IDFA、SIM 卡
						IMSI 信息），我们需要根据设备iMEI和ID限制单用户接口重复调用后台接口，并通过应用启动数据及异常错误日志分析改进性能和用户体验，为用户提供更好的服务。</p>
					<p>消息推送服务供应商：由每日互动股份有限公司提供推送技术服务，我们可能会将您的设备平台、设备厂商、设备品牌、设备识别码等设备信息，应用列表信息、网络信息以及位置相关信息提供给每日互动股份有限公司，用于为您提供消息推送技术服务。我们在向您推送消息时，我们可能会授权每日互动股份有限公司进行链路调节，相互促活被关闭的SDK推送进程，保障您可以及时接收到我们向您推送的消息。详细内容请访问《个推用户隐私政策》。
					</p>
					<p>一、账号注册</p>
					<p>1、用户在使用本服务前需要注册一个“客宝宝APP"账号。“客宝宝APP"账号应当使用手机号码绑定注册,请用户使用尚未与“客宝宝APP"账号绑定的手机号码，以及未被服务根据本协议封禁的手机号码注册“客宝宝APP"账号。服务可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。
					</p>
					<p>2、“客宝宝APP"系基于“客宝宝“的APP产品,用户注册时应当授权客宝宝APP及使用其个人信息方可成功注册“客宝宝APP"账号。故用户完成注册即表明用户同意服务提取、公开及使用用户的信息。
					</p>
					<p>3、鉴于“客宝宝APP"账号的绑定注册方式,您同意服务在注册时将允许您的手机号码及手机设备识别码等信息用于注册。</p>
					<p>在用户注册及使用本服务时,客宝宝APP需要搜集能识别用户身份的个人信息以便服务可以在必要时联系用户,或为用户提供更好的使用体验。客宝宝APP搜集的信息包括但不限于用户的姓名、地址;客宝宝APP同意对这些信息的使用将受限于第三条用户个人隐私信息保护的约束。
					</p>
					<p>二、用户个人隐私信息保护</p>
					<p>1、如果客宝宝APP发现或收到他人举报或投诉用户违反本协议约定的,客宝宝APP有权不经通知随时对相关内容，包括但不限于用户资料、发贴记录进行审查、删除，并视情节轻重对违规账号处以包括但不限于警告、账号封禁、设备封禁、功能封禁的处罚，且通知用户处理结果。
					</p>
					<p>2、因违反用户协议被封禁的用户,可以自行与客宝宝APP联系。其中，被实施功能封禁的用户会在封禁期届满后自动恢复被封禁功能。被封禁用户可提交申诉，客宝宝APP将对申诉进行审查,并自行合理判断决定是否变更处罚措施。
					</p>
					<p>3、用户理解并同意,客宝宝APP有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。
					</p>
					<p>4、用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿客宝宝APP与合作公司、关联公司,并使之免受损害。</p>
					<p>三、用户发布内容规范</p>
					<p>1、本条所述内容是指用户使用服务的过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于账号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、回复或自动回复消息和相关链接页面，以及其他使用账号或本服务所产生的内容。
					</p>
					<p>2、用户不得利用“客宝宝APP"账号或本服务制作、上载、复制、发布、传播如下法律、法规和政策禁止的内容:</p>
					<p>(1)反对宪法所确定的基本原则的;</p>
					<p>(2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的;</p>
					<p>(3)损害国家荣誉和利益的;</p>
					<p>(4)煽动民族仇恨、民族歧视，破坏民族团结的;</p>
					<p>(5)破坏国家宗教政策，宣扬邪教和封建迷信的;</p>
					<p>(6)散布谣言，扰乱社会秩序，破坏社会稳定的;</p>
					<p>(7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的;</p>
					<p>(8)侮辱或者诽谤他人，侵害他人合法权益的;</p>
					<p>(9)含有法律、行政法规禁止的其他内容的信息;</p>
					<p>3、用户不得利用"客宝宝APP"账号或本服务制作上载、复制、发布、传播如下干扰“服务"正常运营，以及侵犯其他用户或第三方合法权益的内容:</p>
					<p>(1)含有任何性或性暗示的;</p>
					<p>(2)含有辱骂、恐吓、威胁内容的;</p>
					<p>(3)含有骚扰、垃圾广告、恶意信息、诱骗信息的</p>
					<p>(4)涉及他人隐私、个人信息或资料的;</p>
					<p>(5)侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的;</p>
					<p>(6)含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。</p>
					<p>四、使用规则</p>
					<p>1、用户在本服务中或通过本服务所传送、发布的任何內容并不反映或代表，也不得被视为反映或代表客宝宝APP的观点、立场或政策,客宝宝APP对此不承担任何责任。</p>
					<p>2、用户不得利用“客宝宝APP账号或本服务进行如下行为:</p>
					<p>(1)提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的;</p>
					<p>(2)强制、诱导其他用户关注、点击链接页面或分享信息的;</p>
					<p>(3)虚构事实、隐瞒真相以误导、欺骗他人的;</p>
					<p>(4)利用技术手段批量建立虚假账号的;</p>
					<p>(5)利用“客宝宝APP"账号或本服务从事任何违法犯罪活动的;</p>
					<p>(6)制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的;</p>
					<p>(7)其他违反法律法规规定、侵犯其他用户合法权益、干扰“客宝宝APP"正常运营或服务未明示授权的行为。</p>
					<p>3、用户须对利用“客宝宝APP"账号或本服务传送信息的真实性、合法性、无害性、准确性、有效性等全权负责，与用户所传播的信息相关的任何法律责法律责任由用户自行承担,与客宝宝APP无关。如因此给客宝宝APP或第三方造成损害的,用户应当依法予以赔偿。
					</p>
					<p>4、客宝宝APP提供的服务中可能包括广告,用户同意在使用过程中显示客宝宝APP和第三方供应商、合作伙伴提供的广告。除法律法规明确规定外，用户应自行对依该广告信息进行的交易负责，对用户因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害,客宝宝APP不承担任何责任。
					</p>
					<p>5、客宝宝商城红包金币均为活动赠送，不作退换与折现。</p>
					<p>五、其他</p>
					<p>1、客宝宝APP郑重提醒用户注意本协议中免除客宝宝APP责任和限制用户权利的条款,请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。</p>
					<p>2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和客宝宝APP之间发生任何纠纷或争议，首先应友好协商解决，协商不成的,用户同意将纠纷或争议提交客宝宝APP住所地有管辖权的人民法院管辖。
					</p>
					<p>3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。</p>
					<p>本《协议》版权由深圳赛通信息科技有限公司所有,深圳赛通信息科技有限公司保留一切对本《协议》解释的权利。</p> -->

					<p style="box-sizing: border-box">提示条款</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						深圳赛通信息科技有限公司（以下可统称为“我们”或“【赛通】”，注册地址【深圳市前海深港合作区前湾一路1号A栋201室(入驻深圳市前海商务秘书有限公司)
						】）高度重视个人信息的保护，在您使用【赛通】平台提供的服务时（以下可称为“【赛通】服务”），我们将按照本隐私政策收集、使用您和您的最终用户（即“您的产品的最终用户”或“您的产品的终端用户”，以下同）的个人信息。我们希望通过本隐私政策向您清晰地介绍我们对您和您的最终用户的个人信息的处理方式，因此我们建议您完整地阅读本隐私政策，以帮助您了解维护自己隐私权的方式。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						本隐私政策适用于【赛通】提供的各项服务，您访问【赛通】平台、使用【赛通】提供的服务，包括但不限于我们提供的网站、SDK、API、插件、组件、代码、工具以及不断创新研发的产品及服务，均适用本隐私政策。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						如您使用【赛通】提供的某项或某几项服务有其单独的隐私政策的，该等服务对应的隐私政策将与本隐私政策一起构成一份完整的【赛通】隐私政策。如您允许我们与第三方网站共享信息，则同时需受第三方网站服务条款和隐私政策的约束。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						需要特别说明的是，第三方作为信息控制方展示、链接或再封装我们的服务并向您提供的产品和服务，须遵循第三方隐私权政策的规定，我们无法了解或控制其信息收集使用目的，在您已查看并接受其隐私政策或隐私政策之前，请谨慎访问或使用其服务。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						&nbsp;在使用【赛通】产品或服务前，请您务必仔细阅读并透彻理解本政策，特别是以粗体下划线标识的条款，您应重点阅读，在确认充分理解并同意后使用相关产品或服务。如您对本隐私政策有任何疑问，您可以通过【赛通】公布的联系方式（legal@sytontech.cn）与我们联系。如果您不同意本隐私政策的内容，您应停止使用【赛通】服务。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">我们的产品基于DCloud uni-app(5+
						App/Wap2App)开发，应用运行期间需要收集您的设备唯一识别码（IMEI/android ID/DEVICE_ID/IDFA、SIM 卡 IMSI
						信息）以提供统计分析服务，并通过应用启动数据及异常错误日志分析改进性能和用户体验，为用户提供更好的服务。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">本隐私政策将帮助您了解以下内容：</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">一、我们如何收集和使用您以及您最终用户的个人信息，权限收集说明</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">二、我们如何使用Cookie 和网络 Beacon</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">三、我们如何共享、转让、公开披露您以及您最终用户的个人信息</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">四、我们如何保护您以及您最终用户的个人信息</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">五、如何管理您及您最终用户的个人信息</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">六、我们如何处理未成年人的信息</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">七、账号注销相关信息及账号注销处理时效</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">八、本隐私政策如何更新</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">九、如何联系我们</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">一、 我们如何收集和使用您以及您最终用户的个人信息，权限收集说明</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">我们收集信息是为了更好、更优、更准确的完成您所选择的服务。我们收集信息的方式如下：</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">1、您向我们提供的信息</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						当您开通【赛通】服务及您在使用【赛通】提供的相关服务时填写、提交或接入的信息，包括您的联系电话、电子邮箱、企业信息、产品信息、推广渠道、应用程序名称相关数据等。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						当您申请企业认证时，我们可能还会要求您提供企业营业执照、统一社会信用代码，企业名称，营业期限，所在地区，详细地址，以及企业认证的支付宝等信息。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">我们收集这些信息是为了向您更好提供服务，为了实现这一目的，我们会把您的信息用于下列用途：</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">（1）向您提供【赛通】各项服务，并维护、改进这些服务。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						（2）向您推荐您可能感兴趣的内容，包括但不限于向您发出产品和服务信息，或通过系统向您展示个性化的第三方推广信息，或者在征得您同意的情况下与【赛通】的合作伙伴共享信息以便他们向您发送有关其产品和服务的信息。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						（3）我们可能使用您的个人信息以预防、发现、调查欺诈、侵权、危害安全、非法或违反与我们或与【赛通】关联方的协议、政策或规则的行为，以保护您、其他用户，或我们或【赛通】关联方的合法权益。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">（4）联系您解决问题。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">（5）经您许可的其他用途。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">&nbsp;2、在您使用服务过程中收集的信息</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">我们会根据您选择不同的【赛通】服务收集不同的信息。我们提供的服务在不同国家或地区可能有所不同。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">1). 请您特别注意：某些第三方还会收集您设备的Mac地址、IMEI号、手机安装APP软件列表等信息，具体请您参见下表：</p>
					<p style="box-sizing: border-box">2).
						当您使用上传图片、发布音视频功能时，我们会请求您授权相机、照片、麦克风等敏感权限。您如果拒绝授权提供，将无法使用此功能，但不影响您正常使用我们APP产品的其他功能。</p>
					<p style="box-sizing: border-box">3).
						当您选择参加我们举办的有关营销活动时，根据活动需要您可提供姓名、通信地址、联系方式、银行账号等信息。这些信息可能包括个人敏感信息（如个人电话号码、银行账号等），是您收到转账或者礼品所必要的，如果您拒绝提供这些信息，我们将可能无法向您转账或发放礼品。
					</p>
					<p style="box-sizing: border-box">4).
						为帮助我们更好地了解我们APP产品及相关服务的运行情况，以便确保运行与提供服务的安全，我们可能记录网络日志信息，以及使用软件及相关服务的频率、崩溃数据、软件安装、使用情况、性能数据等信息
					</p>
					<table width="868">
						<colgroup style="box-sizing: border-box">
							<col width="79" style="box-sizing: border-box;width: 79px">
							<col width="110" style="box-sizing: border-box;width: 111px">
							<col width="150" style="box-sizing: border-box;width: 149px">
							<col width="60" style="box-sizing: border-box;width: 60px">
							<col width="242" style="box-sizing: border-box;width: 243px">
							<col width="226" style="box-sizing: border-box;width: 227px">
						</colgroup>
						<tbody style="box-sizing: border-box">
							<tr style="box-sizing: border-box;height: 22px" class="firstRow">
								<td colspan="6" width="868"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-right-width: 1px; border-right-color: rgb(222, 226, 230);">
									委托处理</td>
							</tr>
							<tr style="box-sizing: border-box;height: 22px">
								<td width="79" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									所属系统</td>
								<td width="111" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									第三方</td>
								<td colspan="2" width="209"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-right-width: 1px; border-right-color: rgb(222, 226, 230); border-left: none;">
									第三方收集个人信息的目的</td>
								<td width="243" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									第三方收集个人信息的范围</td>
								<td width="227" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									第三方收集个人信息的方式</td>
							</tr>
							<tr style="box-sizing: border-box;height: 43px">
								<td width="79" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									<span style="box-sizing: border-box">iOS</span></td>
								<td width="111" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									友盟推送SDK</td>
								<td colspan="2" width="209"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-right-width: 1px; border-right-color: rgb(222, 226, 230); border-left: none;">
									用于消息推送等行为</td>
								<td width="243" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									设备版本、系统版本、生成ID、手机样式、手机名</td>
								<td width="227" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									自动收集，且仅在启动APP、初始化SDK时进行收集</td>
							</tr>
							<tr style="box-sizing: border-box;height: 63px">
								<td width="79" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									<span style="box-sizing: border-box">Android</span></td>
								<td width="111" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									友盟推送SDK</td>
								<td colspan="2" width="209"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-right-width: 1px; border-right-color: rgb(222, 226, 230); border-left: none;">
									用于消息推送等行为</td>
								<td width="243" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									设备版本、系统版本、生成ID、手机样式、手机名、iccid、bssid、MAC地址、IMSI、IMEI</td>
								<td width="227" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									自动收集，且仅在启动APP、初始化SDK时进行收集</td>
							</tr>
							<tr style="box-sizing: border-box;height: 42px">
								<td width="79" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									<span style="box-sizing: border-box">iOS</span></td>
								<td width="111" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">个推
									SDK</td>
								<td colspan="2" width="209"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-right-width: 1px; border-right-color: rgb(222, 226, 230); border-left: none;">
									用于消息推送等行为</td>
								<td width="243" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									设备版本、系统版本、生成ID、手机样式、手机名</td>
								<td width="227" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									自动收集，且仅在启动APP、初始化SDK时进行收集</td>
							</tr>
							<tr style="box-sizing: border-box;height: 63px">
								<td width="79" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									<span style="box-sizing: border-box">Android</span></td>
								<td width="111" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									个推SDK</td>
								<td colspan="2" width="209"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-right-width: 1px; border-right-color: rgb(222, 226, 230); border-left: none;">
									用于消息推送等行为</td>
								<td width="243" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									设备版本、系统版本、生成ID、手机样式、手机名、iccid、bssid、MAC地址、IMSI、IMEI</td>
								<td width="227" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									自动收集，且仅在启动APP、初始化SDK时进行收集</td>
							</tr>
							<tr style="box-sizing: border-box;height: 42px">
								<td width="79" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									<span style="box-sizing: border-box">Android</span></td>
								<td width="111" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									华为推送 SDK</td>
								<td colspan="2" width="209"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-right-width: 1px; border-right-color: rgb(222, 226, 230); border-left: none;">
									用于消息推送等行为</td>
								<td width="243" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									设备版本、系统版本、生成ID、手机样式、手机名</td>
								<td width="227" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									自动收集，且仅在启动APP、初始化SDK时进行收集</td>
							</tr>
							<tr style="box-sizing: border-box;height: 63px">
								<td width="79" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									<span style="box-sizing: border-box">Android</span></td>
								<td width="111" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									小米推送SDK</td>
								<td colspan="2" width="209"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-right-width: 1px; border-right-color: rgb(222, 226, 230); border-left: none;">
									用于消息推送等行为</td>
								<td width="243" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									设备版本、系统版本、生成ID、手机样式、手机名、iccid、bssid、MAC地址、IMSI、IMEI</td>
								<td width="227" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									自动收集，且仅在启动APP、初始化SDK时进行收集</td>
							</tr>
							<tr style="box-sizing: border-box;height: 63px">
								<td width="79" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									<span style="box-sizing: border-box">Android</span></td>
								<td width="111" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									高德地图SDK</td>
								<td colspan="2" width="209"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-right-width: 1px; border-right-color: rgb(222, 226, 230); border-left: none;">
									用于打卡导航等行为</td>
								<td width="243" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									设备版本、系统版本、生成ID、手机样式、手机名、iccid、bssid、MAC地址、IMSI、IMEI</td>
								<td width="227" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									自动收集，且仅在启动APP、初始化SDK时进行收集</td>
							</tr>
							<tr style="box-sizing: border-box;height: 63px">
								<td width="79" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									<span style="box-sizing: border-box">iOS</span></td>
								<td width="111" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									高德地图SDK</td>
								<td colspan="2" width="209"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-right-width: 1px; border-right-color: rgb(222, 226, 230); border-left: none;">
									用于打卡导航等行为</td>
								<td width="243" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									设备版本、系统版本、生成ID、手机样式、手机名、iccid、bssid、MAC地址、IMSI、IMEI</td>
								<td width="227" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									自动收集，且仅在启动APP、初始化SDK时进行收集</td>
							</tr>
						</tbody>
					</table>
					<table width="1127" style="width: 1041px;">
						<colgroup style="box-sizing: border-box">
							<col width="79" style="box-sizing: border-box;width: 79px">
							<col width="93" style="box-sizing: border-box;width: 93px">
							<col width="212" style="box-sizing: border-box;width: 212px">
							<col width="223" style="box-sizing: border-box;width: 223px">
							<col width="278" style="box-sizing: border-box;width: 277px">
							<col width="242" style="box-sizing: border-box;width: 243px">
						</colgroup>
						<tbody style="box-sizing: border-box">
							<tr style="box-sizing: border-box;height: 22px" class="firstRow">
								<td colspan="6" width="1127"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-right-width: 1px; border-right-color: rgb(222, 226, 230);">
									<span style="box-sizing: border-box">共享</span></td>
							</tr>
							<tr style="box-sizing: border-box;height: 22px">
								<td width="79" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									所属系统</td>
								<td width="93" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">第三方
								</td>
								<td width="212" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									第三方收集个人信息的目的</td>
								<td width="223" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									第三方收集个人信息的范围</td>
								<td width="277" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									第三方收集个人信息的方式</td>
								<td width="243" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									我方从第三方获取的个人信息</td>
							</tr>
							<tr style="box-sizing: border-box;height: 43px">
								<td width="79" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									<span style="box-sizing: border-box">iOS</span></td>
								<td width="93" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									<span style="box-sizing: border-box">微信SDK</span></td>
								<td width="212" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									用于授权登录和支付</td>
								<td width="223" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									设备版本、系统版本、生成ID、手机样式、手机名</td>
								<td width="277" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									自动收集，且仅在启动APP、初始化SDK时进行收集</td>
								<td width="243" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									<span style="box-sizing: border-box">openid、unionid、昵称、性别、语言、城市、省份、国家、头像</span></td>
							</tr>
							<tr style="box-sizing: border-box;height: 42px">
								<td rowspan="2" width="79"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									<span style="box-sizing: border-box">Android</span></td>
								<td rowspan="2" width="93"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									微信SDK</td>
								<td rowspan="2" width="212"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									用于授权登录和支付</td>
								<td rowspan="2" width="223"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									设备版本、系统版本、生成ID、手机样式、手机名、iccid、bssid、MAC地址、IMSI、IMEI</td>
								<td width="277" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									自动收集，且仅在启动APP、初始化SDK时进行收集<span style="box-sizing: border-box"></span></td>
								<td rowspan="2" width="243"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									<span style="box-sizing: border-box">openid、unionid、昵称、性别、语言、城市、省份、国家、头像</span></td>
							</tr>
							<tr style="box-sizing: border-box;height: 84px">
								<td width="277" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									每一订单支付时获取订单下的交易信息（包括订单号、交易商品、交易金额），以确认并完成您的支付指令</td>
							</tr>
							<tr style="box-sizing: border-box;height: 42px">
								<td rowspan="2" width="79"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									<span style="box-sizing: border-box">Android</span></td>
								<td rowspan="2" width="93"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									支付宝 SDK</td>
								<td rowspan="2" width="212"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									用于授权登录和支付</td>
								<td rowspan="2" width="223"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									设备版本、系统版本、生成ID、手机样式、手机名、iccid、bssid、MAC地址、IMSI、IMEI</td>
								<td width="277" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									自动收集，且仅在启动APP、初始化SDK时进行收集<span style="box-sizing: border-box"></span></td>
								<td rowspan="2" width="243"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									买家支付宝账号对应的支付宝唯一用户号（以2088开头的纯16位数字）、匿名化处理后的手机号</td>
							</tr>
							<tr style="box-sizing: border-box;height: 84px">
								<td width="277" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									每一订单支付时获取订单下的交易信息（包括订单号、交易商品、交易金额），以确认并完成您的支付指令</td>
							</tr>
							<tr style="box-sizing: border-box;height: 21px">
								<td rowspan="3" width="79"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									<span style="box-sizing: border-box">Android</span></td>
								<td rowspan="3" width="93"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									<span style="box-sizing: border-box">华为SDK</span></td>
								<td rowspan="3" width="212"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									用于授权登录和支付</td>
								<td rowspan="3" width="223"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									设备版本、系统版本、生成ID、手机样式、手机名、iccid、bssid、MAC地址、IMSI、IMEI</td>
								<td width="277" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									获取权限：应用内安装其他应用<span style="box-sizing: border-box"></span></td>
								<td rowspan="3" width="243"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									<span style="box-sizing: border-box">openid、头像、匿名化处理后的手机号、用户支付完成后的订单和票据信息</span>
								</td>
							</tr>
							<tr style="box-sizing: border-box;height: 42px">
								<td width="277" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									自动收集，且仅在启动APP、初始化SDK时进行收集</td>
							</tr>
							<tr style="box-sizing: border-box;height: 84px">
								<td width="277" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									每一订单支付时获取订单下的交易信息（包括订单号、交易商品、交易金额），以确认并完成您的支付指令</td>
							</tr>
							<tr style="box-sizing: border-box;height: 42px">
								<td rowspan="2" width="79"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									Android</td>
								<td rowspan="2" width="93"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									VIVO SDK</td>
								<td rowspan="2" width="212"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									用于授权登录和支付</td>
								<td rowspan="2" width="223"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									设备版本、系统版本、生成ID、手机样式、手机名、iccid、bssid、MAC地址、IMSI、IMEI</td>
								<td width="277" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									自动收集，且仅在启动APP、初始化SDK时进行收集</td>
								<td rowspan="2" width="243"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									Openid、vivo uid、支付完成后的订单和票据信息</td>
							</tr>
							<tr style="box-sizing: border-box;height: 84px">
								<td width="277" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									每一订单支付时获取订单下的交易信息（包括订单号、交易商品、交易金额），以确认并完成您的支付指令支付</td>
							</tr>
							<tr style="box-sizing: border-box;height: 21px">
								<td rowspan="3" width="79"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									Android</td>
								<td rowspan="3" width="93"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									OPPO SDK</td>
								<td rowspan="3" width="212"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									用于支付</td>
								<td rowspan="3" width="223"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									设备版本、系统版本、生成ID、手机样式、手机名、iccid、bssid、MAC地址、IMSI、IMEI</td>
								<td width="277" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									获取权限：访问存储权限</td>
								<td rowspan="3" width="243"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									OPPO账户的用户名、openid、支付完成后的订单和票据信息</td>
							</tr>
							<tr style="box-sizing: border-box;height: 42px">
								<td width="277" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									自动收集，且仅在启动APP、初始化SDK时进行收集</td>
							</tr>
							<tr style="box-sizing: border-box;height: 188px">
								<td width="277" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									每一订单支付时获取订单下的交易信息（包括订单号、交易商品、交易金额），以确认并完成您的支付指令。 特提醒您注意：在提供支付功能之前，OPPO
									SDK会申请“存储空间权限"。如您拒绝授权，您仍然可以使用OPPO支付功能，但OPPO可能会重复向您请求授权。</td>
							</tr>
							<tr style="box-sizing: border-box;height: 42px">
								<td rowspan="2" width="79"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									Android</td>
								<td rowspan="2" width="93"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									小米SDK</td>
								<td rowspan="2" width="212"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									用于授权登录和支付</td>
								<td rowspan="2" width="223"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									设备版本、系统版本、生成ID、手机样式、手机名、iccid、bssid、MAC地址、IMSI、IMEI</td>
								<td width="277" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									自动收集，且仅在启动APP、初始化SDK时进行收集</td>
								<td rowspan="2" width="243"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									小米账号的uid、是否实名、用户年龄（可选）、用户支付完成后的订单和票据信息</td>
							</tr>
							<tr style="box-sizing: border-box;height: 84px">
								<td width="277" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									每一订单支付时获取订单下的交易信息（包括订单号、交易商品、交易金额），以确认并完成您的支付指令</td>
							</tr>
							<tr style="box-sizing: border-box;height: 42px">
								<td rowspan="2" width="79"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									Android</td>
								<td rowspan="2" width="93"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									360SDK</td>
								<td rowspan="2" width="212"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									用于授权登录和支付</td>
								<td rowspan="2" width="223"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									设备版本、系统版本、生成ID、手机样式、手机名、iccid、bssid、MAC地址、IMSI、IMEI</td>
								<td width="277" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									自动收集，且仅在启动APP、初始化SDK时进行收集</td>
								<td rowspan="2" width="243"
									style="box-sizing: border-box; padding: 0px; vertical-align: middle; border-bottom-width: 1px; border-bottom-color: rgb(222, 226, 230); border-top: none;">
									360账号的uid、是否实名、用户年龄（可选）、用户支付完成后的订单和票据信息</td>
							</tr>
							<tr style="box-sizing: border-box;height: 84px">
								<td width="277" style="box-sizing: border-box; padding: 0px; vertical-align: middle;">
									每一订单支付时获取订单下的交易信息（包括订单号、交易商品、交易金额），以确认并完成您的支付指令</td>
							</tr>
						</tbody>
					</table>
					<p style="box-sizing: border-box">3、最终用户数据的授权与使用</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						您为最终用户的信息控制者，我们为您提供数据处理服务，但无法控制您如何使用属于或关于您的最终用户的数据。您应当采用符合适用的法律和道德义务的方式使用您最终用户的数据。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						如果您接受本隐私政策并把我们的SDK、API、JS代码或其他统计分析工具、开发者工具集成到您的产品中，您应当根据适用的法律、法规和监管要求和本隐私政策的规定，保证我们可以合法地收集和使用您的最终用户的相关个人信息，最终以统计分析数据、人群画像、标签等形式体现。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						您需保证您已经遵守并将持续遵守适用的法律、法规和监管要求，包括但不限于制定和公布有关个人信息保护和隐私保护的相关政策，您同时应当向最终用户提供易于操作的选择机制、告知最终用户如何行使选择权。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						为了便于您的最终用户了解我们的隐私政策，或者适用的法律、法规和监管要求，您应当在您的隐私权政策中嵌入我们的隐私政策的链接。如果您的最终用户不希望其个人信息被我们处理和使用，您可以告知您的最终用户登录网址进行退出操作。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">4、权限收集说明</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">1)、读取及修改存储卡权限：允许应用修改或删除存储卡上的照片、媒体内容和文件。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">2)、获取定位权限：允许应用能获取用户位置，以便实现签到打卡功能。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">3)、使用相机权限：允许应用在用户使用相机或相册，更新自己的头像。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">4)、获取手机信息权限：允许应用获取本机设备信息，以便生成唯一标识</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">5)、修改系统设置项权限：允许应用修改设置应用中的设置项。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">6)、获取手机安装APP软件列表：允许应用获取本机已安装APP软件列表，以便自动更新或者安装客宝宝APP。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						创建账号时，您需要提供手机号码（个人敏感信息，用于接受验证码匹配个人身份）。您只有提供真实准确的上述信息，才能成功注册账号并使用产品和/或服务的核心功能。如果您选择不提供上述为实现核心产品功能的必备信息，或将导致我们无法为您提供该核心产品功能。在您主动注销账号之后，我们将根据法律的要求删除您的个人信息。
						我们承诺，收集上述信息是用于为您提供账号注册、登录服务以及保障您的账号安全，防范安全风险。如您拒绝授权此类信息，则您将无法使用第三方平台的账号登录我们平台，但不影响我们提供的其他产品和服务的正常使用。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						同时，您需理解，手机号码或验证码匹配结果属于您的个人敏感信息，我们收集该类信息是基于法律法规的相关要求，如您拒绝提供可能导致您无法注册账号并使用相关产品功能，请您谨慎考虑后再选择是否提供。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						您在购买我们的产品或服务中准备消费支付时，需要使用到支付功能。在支付过程中，您需要向我们提供一些与完成交易相关联的信息，包括如下：购买的产品或服务信息。我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、查询订单信息、提供客户服务等。您可以选择我们合作的第三方支付机构（如微信支付、支付宝）所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的订单信息及对账信息与这些支付机构共享以确认您的支付指令并完成支付。
						我们的合作伙伴包括以下类型：</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						商品或技术服务的供应商。我们可能会将您的个人信息共享给支持我们功能的第三方。这些支持包括为我们提供支付服务、数据处理等。我们共享这些信息的目的是可以实现我们产品与/或服务的核心功能；</p>
					<p style="box-sizing: border-box">
						第三方SDK类服务商。我们的产品中可能会包含第三方SDK或其他类似的应用程序，如您在我们平台上使用这类由第三方提供的服务时，您同意将由其直接收集和处理您的信息（如以嵌入代码、插件等形式）。我们对接入的相关第三方SDK在以下列明：
					</p>
					<p style="box-sizing: border-box">
						支付宝SDK：由支付宝（中国）网络技术有限公司提供支付服务，我们可能会将您的支付宝信息提供给支付宝（中国）网络技术有限公司，用于为您提供支付服务（此产品的隐私政策条款，可参考 支付宝隐私政策）
					</p>
					<p style="box-sizing: border-box">
						友盟SDK：由北京锐讯灵通科技有限公司提供App崩溃报警、用户应用行为统计和推送服务，我们可能会将您的SD卡信息提供给北京锐讯灵通科技有限公司，用于为您提供推送服务（此产品的隐私政策条款，可参考
						友盟平台服务协议）</p>
					<p style="box-sizing: border-box">微信SDK：由腾讯公司提供服务，我们可能会将您的手机号信息提供给腾讯公司(此产品的隐私政策条款，用于为您提供微信支付服务，可参考
						腾讯公司服务协议：https://www.tencent.com/zh-cn/service-agreement.html）</p>
					<p style="box-sizing: border-box">我们非常重视信息安全，我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						我们成立了专责团队负责研发和应用多种安全技术和程序等，我们会对安全管理负责人和关键安全岗位的人员进行安全考核，我们建立了完善的信息安全管理制度和内部安全事件处置机制等，我们会采取适当的符合业界标准的安全措施和技术手段存储和保护您的个人信息，以防止您的信息丢失、收到被未经授权的访问、使用、毁损或泄漏。根据我们的安全管理制度，个人信息泄露、毁损或丢失事件被列为重大安全事件，一经发生将启动公司紧急预案，由网络运营部、信息管理部、客户服务部、法务部等多个部门组成联合应急响应小组处理；
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						我们会对员工进行数据安全的意识培养和安全能力的培训和考核，会对处理个人信息的员工进行身份认证及权限控制，并会与接触您个人信息的员工、合作伙伴签署保密协议，明确岗位职责及行为准则，若有违反保密协议的行为，会被立即终止与我们的劳动或合作关系，并会被追究相关法律责任，对接触个人信息人员在离岗时也提出了保密要求。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">我们会严格按照《网络安全法》及《关于开展APP侵害用户权益专项整治工作的通知》，为保障个人信息安全竭尽全力。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">二、 我们如何使用 Cookie 和网络 Beacon</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						为使您获得更轻松的访问体验，您访问【赛通】平台或使用【赛通】提供的服务时，我们可能会通过小型数据文件识别您的身份，这么做是帮您省去重复输入注册信息的步骤，或者帮助判断您的账号安全。这些数据文件可能是Cookie、Flash
						Cookie，或您的浏览器或关联应用程序提供的其他本地存储（以下合称“Cookie”）。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						请您理解，我们的某些服务只能通过使用Cookie才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以打开浏览器的设置选项，选择隐私设置，勾选“不跟踪”，或者禁止Cookie，这样您的数据将不会被我们追踪到。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">网页上常会包含一些电子图象（称为“单像素GIF 文件”或 “网络
						Beacon”），使用网络Beacon可以帮助网站计算浏览网页的用户或访问某些Cookie，我们会通过网络Beacon收集您浏览网页活动的信息，例如您访问的页面地址、您先前访问的援引页面的位址、您停留在页面的时间、您的浏览环境以及显示设定等。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">三、 我们如何共享、转让、公开披露您以及您最终用户的个人信息</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">1、共享</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						我们对您的信息承担保密义务，不会为满足第三方的营销或非法目的而向其出售或出租您的信息。除下列情况外，我们不会与任何第三方共享您的个人信息，以及最终用户的个人信息：</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">（1）事先获得您的同意或授权。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">（2）根据法律法规的规定或行政或司法机构的要求。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">（3）如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">（4）只有共享您的信息，才能提供您需要的服务，或处理您与他人的纠纷或争议。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						（5）与广告主、媒体等合作伙伴共享。我们会与这些合作伙伴合作以多种形式将经数据中台处理、加工后的脱敏数据用于包括优化广告投放和提升营销效果等商业化使用。除非得到您和最终用户的授权，否则我们不会将您以及您最终用户的个人身份信息向合作伙伴分享。我们会向这些合作伙伴提供有关广告投放人群策略数据或广告主广告效果的数据，而不会提供任何个人身份信息，或者我们将这些信息进行汇总，以便它无法作身份识别。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">我们会与共享的合作伙伴签署严格的保密协定，要求他们按照我们的隐私政策以及采取严格的数据安全措施来处理和使用数据。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">（6）向【赛通】的关联公司分享您的个人信息。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">2、转让</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">我们不会向任何第三方转让您以及您最终用户的个人信息，除非以下情形：</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">（1）获得您以及您最终用户的同意。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						（2）在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">&nbsp; &nbsp; 3、公开披露&nbsp;</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">我们仅会在以下情况下，公开披露您或您最终用户的个人信息</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">（1）获得您或您最终用户明确同意；</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">（2）基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您或您最终用户的个人信息。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">（3）在紧急情况下，经合理判断是为了保护我们、我们的客户、最终用户或其他人的重要合法权益。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">四、 我们如何保护您以及您最终用户的个人信息</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						为了保障您以及您最终用户的信息安全，我们将按照行业通行标准、努力采取合理的物理、电子和管理方面的安全措施来保护您的信息，并尽最大合理努力使您以及您最终用户的信息不会被泄漏、毁损或者丢失。在您的浏览器与服务器之间交换数据时受
						SSL协议加密保护；我们对网站提供HTTPS协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						我们有行业先进的以数据为核心、围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						我们收集的信息保存于不向公众开放的安全运行环境之中。为避免未经授权访问您的信息，我们将其保存在有防火墙保护并可能加密的服务器之中。但是，没有任何系统是绝对安全的，请您理解在信息网络上不存在“完善的安全措施”。即使我们做出了很大努力，仍有可能无法杜绝对您以及您最终用户的个人信息的非法访问。此外，由于无法判断他人的陈述是否属实，我们可能会被不可靠的、有误导性的或非法的信息所蒙蔽。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						如不幸发生个人信息安全事件，我们将按照法律法规的要求向您或您的最终用户告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您以及您的最终用户可自主防范和降低风险的建议、对您以及您的最终用户的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您以及您的最终用户，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您及您最终用户的个人信息，除非法律有强制的存留要求。而我们判断前述期限的标准包括：</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">1、完成与您相关的服务目的、维护相应服务及业务记录、应对您可能的业务需求；</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">2、保证我们为您提供服务的安全和质量；</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">3、您是否同意更长的留存期间；</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">4、是否存在保留期限的其他特别约定。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">在您的个人信息超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">五、 如何管理您及您最终用户的个人信息</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">您可以通过以下方式访问及管理您的信息。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						1、查询、更正和补充您的信息。我们将努力使您能审阅、更正或补充您存在我们这里的信息。如需审阅、更正或补充，请访问我们的网站并登录您的账号予以操作。如某项权利的行使无法在账号页面操作，您可以通过本政策中的联系方式与我们联系，我们协助您进行相应操作。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">2、账号注销与删除您的个人信息。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						如您不希望继续使用我们的产品，您可以通过工单系统（具体路径为登陆您的账号，点击客服工单，选择法务问题咨询以及其他权利咨询类型进行工单反馈）向我们提交注销账号的申请，我们通常会在15个工作日内回应您的申请需求，但为了您的账号安全，我们会辨别您身份、核实并处理您的请求，我们将需要您提交充分有效的身份信息。账号注销后，我们将不再为您提供服务，如果您希望我们删除您的个人信息，您也可以向我们提交删除您个人信息的申请。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">3、获取个人信息副本。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						您有权通过向我们发送请求，获取您有关个人基本资料的副本。在技术可行的前提下，例如数据接口匹配，我们还可按您的要求，直接将您的个人信息副本传输给您指定的第三方。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">如您的最终用户需要获取其个人信息的副本，可以向我们发起请求，当我们完成最终用户的身份核验工作后，将返还其个人信息副本。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">六、 我们如何处理未成年人的信息</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">我们非常重视未成年人的个人信息保护，我们不接受任何未成年人注册成为我们的用户。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						如您的应用是针对未成年人而开发的，请您采取必要的措施确保您最终用户的注册与使用均已获得其监护人的同意，同时，您应在您的隐私权政策中履行相应的告知义务。提醒您注意的是，作为未成年人类的应用，您还需要遵守应用市场有关未成年人类应用的审核标准与指南，以免对您的应用上架或正常运营产生影响。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						受制于现有技术和业务模式，我们很难主动识别未成年人的信息，如果未成年人的监护人发现存在未经授权而收集未成年人个人信息，监护人可通知我们予以删除，如我们自己发现前述情形，我们也会主动予以删除。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">七、 账号注销相关信息及账号注销处理时效</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">您可以致电我们的客服电话：4008226635，告知我们的客服要求注销账号，我们会在征得您同意后，在三个工作日内为您注销账号。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						为保障您的账号与资产安全，请您在注销账号前检查与确认该账号已同时满足：账号为您本人注册；账号内无资产、账号内无正在进行中的投诉；您申请注销账号的行为视为您自愿放弃此账号内的所有资产和权益（包括但不限于您已订阅但未使用完毕的服务）、因历史交易可能产生的售后服务等权益。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						请您知悉，在注销账号后，除非根据法律法规或根据监管部门的要求保留或存储您和/或您孩子的个人信息，否则账号注销处理时效为即时。我们将即时删除您和/或您孩子的个人信息。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						您注销账号的行为是不可逆的，一旦您的账号被注销，我们将不再收集您的信息，也不再为您提供产品与/或服务；该账号在使用期间已产生但未使用完毕的所有权益将被清除，该账号下的内容、信息等将会被删除，除非法律法规另有规定或监管部门另有要求。因此我们建议您谨慎选择注销账号。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">八、 本隐私政策如何更新</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						我们可能适时会对本隐私政策进行调整或变更，本隐私政策的任何更新将以标注更新时间的方式公布在【赛通】网站上，除法律法规或监管规定另有强制性规定外，经调整或变更的内容一经通知或公布后的7日后生效。未经您明确同意我们不会限制您按照本政策所应享有的权利。对于重大变更，我们还会提供更为显著的通知（包括通过网站或客户端首页公示的方式进行通知甚至向您提供弹窗提示）。
					</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">本政策所指的重大变更包括但不限于：</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">1、我们的服务模式发生重大变化。如处理用户信息的目的、用户信息的使用方式等。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">2、我们在控制权、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">3、个人信息共享、转让或公开披露的主要对象发生变化。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">4、您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">5、我们负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">6、个人信息安全影响评估报告表明存在高风险时。</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">九、如何联系我们</p>
					<p style="box-sizing: border-box"><br></p>
					<p style="box-sizing: border-box">
						如果您对本隐私政策有任何问题、建议或投诉，请您通过客服电话或者官网客服邮箱联系我们。我们将在收到咨询后的15个工作日内予以回复。&nbsp;</p>
					<p style="box-sizing: border-box"><br></p>
					<p><br></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "privacy",
		mounted() {
			console.log("download", Boolean(this._isMobile()));
			if (this._isMobile()) {
				// 为true是移动端,跳至移动端页面
				console.log("移动端");
				this.$router.replace('/mobilePrivacy')
			} else {
				// 为false是pc端，跳至pc端页面
				console.log("PC端");
				this.$router.replace('/privacy')
			}
		},
		methods: {
			handleClick(tab, event) {
				console.log(tab, event);
			},
			// 判断是什么设备
			_isMobile() {
				let plat = navigator.userAgent.match( // 判断不同端
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				return plat;
			}
		},
	}
</script>
<style lang="scss" scoped>
	.banner {
		height: 500px;
		background-image: url("../../../public/about_banner1.png");
		color: #fff;
		text-align: left;

		.banner-content {
			margin-left: 500px;

			h2 {
				font-size: 54px;
				color: #fff;
				line-height: 56px;
				padding-top: 180px;
			}

			p {
				font-size: 26px;
				color: #fff;
				line-height: 30px;
				margin-top: 21px;
				font-family: 'pingfang';
			}

		}
	}

	.brief {
		height: 100%;

		.brief-title {
			font-size: 32px;
			font-weight: bold;
			padding-top: 70px;
		}

		.brief-content {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 50px;

			.brief-img {
				margin-right: 100px;
			}

			.brief-content {
				display: block;
				width: 100%;
				margin-top: 20px;
				text-indent: 2em;
				line-height: 35px;
				text-align: left;

			}
		}
	}

	.culture {
		height: 646px;
		background: url("../../../public/about_culBg.png");

		.culture-title {
			font-size: 32px;
			font-weight: bold;
			line-height: 35px;
			padding-top: 100px;
			color: #ffffff;
		}

		.culture-content {
			display: flex;
			justify-content: center;
			padding-top: 100px;

			.culture-item {
				width: 300px;
				text-align: left;
				margin-right: 30px;

				.item-img {}

				.item-title {
					font-size: 18px;
					color: #ffffff;
					padding: 20px 0 10px;
					display: flex;
					align-items: center;
				}

				.item-content {
					font-size: 16px;
					color: #ffffff;
					line-height: 30px;
					border-top: 1px solid #dddddd;
					padding-top: 5px;
				}
			}
		}
	}

	.honor {
		padding-bottom: 50px;

		.honor-title {
			font-size: 32px;
			line-height: 35px;
			padding: 70px;
		}

		.honor-content {
			display: flex;
			justify-content: center;

			.honor-item {
				margin-right: 50px;
			}
		}
	}
</style>
